import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import AltHeader from "../components/AltHeader"
import Textures from "../components/Textures"
import CaseStudyTransitionLinks from "../components/CaseStudyTransitionLinks"
import { css } from '@emotion/core'

export default function Work(props) {
  const cases = props.data.allContentfulCaseStudies.nodes
  const pages = [
    {
      url: "/work",
      name: "Work"
    },
    {
      url: "/about",
      name: "About"
    },
    {
      url: "/",
      name: "Home"
    }
  ]

  return (
    <main>
      <SEO title="Work" />
      <Textures/>
      <main className="min-h-screen w-screen bg-primary">
        <AltHeader pages={pages}/>
        <section className="lg:h-screen flex flex-col items-center justify-center">
          <div style={props} className="link-container relative lg:absolute p-6"
            css={css`
              max-width: 920px;
            `
          }>
            {cases.map((c,i) => {
              const title = c.title
              const excerpt = c.projectType
              const slug = c.slug

              return (
                <CaseStudyTransitionLinks key={i} inline={true} content={{title: title, excerpt: excerpt, slug: slug}}/>
              )
            })}
          </div>
        </section>
      </main>
    </main>
  )
}

export const query = graphql`
  query CasesQuery {
    allContentfulCaseStudies(sort: {fields: publishedDate, order: DESC}) {
      nodes {
        title
        slug
        projectType
      }
    }
  }
`
