import { React, useEffect, useState } from "react"
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import TransitionLink from "gatsby-plugin-transition-link"
import { TweenMax, Power2 } from "gsap"

const Title = styled.h2`
  display: inline;
  font-size: 20px;
  font-weight: normal;
  transition: color 0.3s ease-in-out;
  line-height: 2;
  margin-right: 10px;

  @media screen and (min-width: 360px) {
    font-size: 22px;
  }

  @media screen and (min-width: 768px) {
    font-size: 30px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 32px;
  }

  &:before {
    content: "⁎";
    font-size: 50px;
    line-height: 0;
    margin-right: 3px;

    @media screen and (min-width: 360px) {
      font-size: 62px;
    }

    @media screen and (min-width: 1024px) {
      font-size: 80px;
    }
  }

  .title-excerpt {
    display: none;
    top: -10px;
    margin-left: 3px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: lighter;

    @media screen and (min-width: 360px) {
      display: inline-block;
    }

    @media screen and (min-width: 1024px) {
      top: -20px;
    }
  }
`

export default function CaseStudyTransitionLinks(props) {
  const title = props.content.title
  const excerpt = props.content.excerpt
  const slug = props.content.slug
  const inline = props.inline ? "inline" : ""

  const [isMobile, setIsMobile] = useState(null)

  const screenCheck = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    screenCheck()
    window.addEventListener('resize', () => {screenCheck()})
  })

  useEffect(() => {
    let container = document.querySelector(".link-container")
    let children = Array.from(container.childNodes) 

    children.forEach((child, i) => {
      let translate = 120 - (i * 20)
      if (translate < 0) translate = 0;
      TweenMax.delayedCall(1.3, () => {
        TweenMax.to(child, 0.75, {opacity: 1, delay: i / 10})
      })
    })
  })

  const animateOut = (e, exit, node) => {
    if (!isMobile) {
      let container = node.querySelector(".link-container")
      let children = Array.from(container.childNodes) 

      TweenMax.to(container, 1.65, {left: "63px", ease: Power2.easeOut, delay: 0.1})
      TweenMax.to(container, 1.25, {top: "40px", ease: Power2.easeInOut})
      children.forEach((child, i) => {
        let translate = 120 - (i * 20)
        if (translate < 0) translate = 0;

        TweenMax.to(child, 0.75, {display: "block", ease: Power2.easeIn, delay: i / 10})
        TweenMax.to(child, 0.5, {transform: `translateX(-${translate}px)`, yoyo: true, repeat: 1, ease: Power2.easeIn, delay: i / 10})
        
        if (child === e.target.parentElement) {
          TweenMax.to(child, 0.75, {opacity: 0, ease: Power2.easeIn})
          TweenMax.to(child, 0.75, {opacity: 1, ease: Power2.easeIn, delay: 0.7})
        } else {
          TweenMax.to(child, 0.75, {opacity: 0, ease: Power2.easeIn})
          TweenMax.to(child, 0.75, {opacity: 0.6, ease: Power2.easeIn, delay: 0.7})
        }
      })
    }
  }

  return (
    <TransitionLink
      to={`/work/${slug}`}
      exit={{
        trigger: ({ e, exit, node }) => animateOut(e, exit, node),
        length: 2,
      }}
      entry={{
        delay: 1.4
      }}
      css={css`
        display: block;
        z-index: 1;
        transition: transform 0.33s var(--ease-out-quart);
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        opacity: 0;
      
        @media screen and (min-width: 1024px) {
          display: ${inline};
        }
      `
    }>
      <Title>
        {title}
        <sup className="title-excerpt">{excerpt}</sup>
      </Title>
    </TransitionLink>
  )
}


